@import '../variables';
@import '../mixin';

#confirm {
  text-align: center;
  padding: 60px 15px;
}
@-webkit-keyframes checkmark {
    0% {
        stroke-dashoffset: 50px
    }
    100% {
        stroke-dashoffset: 0
    }
}
@-ms-keyframes checkmark {
    0% {
        stroke-dashoffset: 50px
    }
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes checkmark {
    0% {
        stroke-dashoffset: 50px
    }
    100% {
        stroke-dashoffset: 0
    }
}

@-webkit-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }
    100% {
        stroke-dashoffset: 480px
    }
}

@-ms-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }
    100% {
        stroke-dashoffset: 480px
    }
}

@keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }
    100% {
        stroke-dashoffset: 480px
    }
}
.inlinesvg .svg svg {
    display: inline;
}
.icon--order-success svg path {
    -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
    animation: checkmark 0.25s ease-in-out 0.7s backwards
}
.icon--order-success svg circle {
    -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
    animation: checkmark-circle 0.6s ease-in-out backwards
}